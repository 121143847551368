var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { title: _vm.title, icon: "mdi-timeline-clock-outline" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.acesso.instituicao.settings.allow_time_table_by_date
                ? _c("s-switch", {
                    staticClass: "mt-5",
                    attrs: {
                      inset: "",
                      "text-helper": "Alocar Horários por datas específicas",
                      label: "Por Data"
                    },
                    model: {
                      value: _vm.byDate,
                      callback: function($$v) {
                        _vm.byDate = $$v
                      },
                      expression: "byDate"
                    }
                  })
                : _vm._e(),
              _vm.user.is_internal
                ? _c("emc-button-icon", {
                    attrs: {
                      icon: "mdi-content-copy",
                      text: "Replicar Horário",
                      color: "light-green"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.showReplicateForm = true
                      }
                    }
                  })
                : _vm._e(),
              _vm.isMaster
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "teal darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            _vm.showMailHourTeacher = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [_vm._v("mdi-calendar-clock")])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        134224048
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Enviar Email com horários do professor")
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "teal darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showFilterPrints = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-printer-check")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Impressão de Horários")])]
              ),
              !_vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "purple darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(28)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-clipboard-alert-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3507109711
                      )
                    },
                    [_c("span", [_vm._v(" Relatório de Pendências ")])]
                  )
                : _vm._e(),
              _c("s-icon-report-download-optimized", {
                attrs: {
                  "show-message": "",
                  "tool-tip": "Horários em Linhas",
                  report: "12"
                }
              }),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _c("emc-excel-import-icon", {
                attrs: {
                  color: "success darken-1",
                  "tool-tip": "Importar Horário (Por código de horário)",
                  showClearItems: "",
                  param: "class_schedules_code_hour"
                },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c("emc-excel-import-icon", {
                attrs: {
                  color: "primary",
                  "tool-tip": "Importar Horário (Por horário de início e fim)",
                  showClearItems: "",
                  param: "class_schedules"
                },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c("v-divider", {
                staticClass: "mr-1",
                attrs: { vertical: "", inset: "" }
              }),
              _c(
                "span",
                { staticClass: "ma-1" },
                [
                  _c("sys-schedule-pop-over", { attrs: { config: _vm.config } })
                ],
                1
              ),
              _vm.isMaster
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          loading:
                                            _vm.progressRefreshPendencies,
                                          large: "",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.refreshPendencies()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          color: "red darken-1"
                                        }
                                      },
                                      [_vm._v("mdi-refresh-circle")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1124071390
                      )
                    },
                    [_c("span", [_vm._v(" Atualizar Pendências ")])]
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    loading: _vm.progress,
                                    disabled: !_vm.group,
                                    large: "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.selectGroup(
                                        _vm.group,
                                        false,
                                        true
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { btn: "", color: "green darken-1" } },
                                [_vm._v("mdi-refresh-circle")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Atualizar ")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    icon: "",
                                    dark: "",
                                    large: "",
                                    color: "red"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.drawer = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [_vm._v("mdi-format-list-checkbox")])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Lista de turmas...")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-message-chat", {
        attrs: {
          show: _vm.dialogChatGroup,
          model: _vm.group,
          type: "group",
          title: "Registro de Anotações da Turma"
        },
        on: {
          "update:show": function($event) {
            _vm.dialogChatGroup = $event
          }
        }
      }),
      _c("sys-print-time-table", {
        attrs: { show: _vm.showFilterPrints },
        on: {
          "update:show": function($event) {
            _vm.showFilterPrints = $event
          }
        }
      }),
      _c("sys-schedule-replicate", {
        attrs: { show: _vm.showReplicateForm },
        on: {
          "update:show": function($event) {
            _vm.showReplicateForm = $event
          }
        }
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pa-0 ma-1",
          staticStyle: { "margin-top": "0px" },
          attrs: { fluid: "" }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "9" } },
                [
                  _c("v-simple-table", {
                    staticClass: "table-schedule",
                    attrs: {
                      oncontextmenu: "return false;",
                      "fixed-header": "",
                      height: "400"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: { width: "40px" }
                                    },
                                    [
                                      _vm.group
                                        ? _c(
                                            "span",
                                            [
                                              _c("v-row", [
                                                _c("strong", [
                                                  _vm._v(_vm._s(_vm.group.name))
                                                ])
                                              ]),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "emc-message-box",
                                                                      {
                                                                        staticStyle: {
                                                                          cursor:
                                                                            "pointer"
                                                                        },
                                                                        attrs: {
                                                                          model:
                                                                            _vm.group
                                                                        },
                                                                        on: {
                                                                          "update:model": function(
                                                                            $event
                                                                          ) {
                                                                            _vm.group = $event
                                                                          },
                                                                          onOpen: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.dialogChatGroup = true
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        100426606
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Registro de Anotações da turma"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm.group &&
                                                  _vm.group.validated_time
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticStyle: {
                                                            cursor: "default"
                                                          },
                                                          attrs: {
                                                            icon: "",
                                                            "x-small": "",
                                                            color: "success"
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-check-bold"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._l(_vm.days_number, function(d, i) {
                                    return _c(
                                      "th",
                                      { key: i, staticClass: "text-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.days_name[d - 1]) +
                                            " "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm.renderComponent
                              ? _c(
                                  "tbody",
                                  _vm._l(_vm.hours, function(hour) {
                                    return _c(
                                      "tr",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.showHours ||
                                              (_vm.validateShift(
                                                hour,
                                                hour.shift_id
                                              ) &&
                                                _vm.hasGroups(hour)),
                                            expression:
                                              "showHours || (validateShift(hour, hour.shift_id) && hasGroups(hour))"
                                          }
                                        ],
                                        key: hour.id,
                                        class: _vm.disableHour(hour)
                                          ? "inativo"
                                          : "",
                                        on: { mousedown: _vm.cancelSelection }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(hour.start) + " "
                                          ),
                                          _c("br"),
                                          _vm._v(" " + _vm._s(hour.end) + " ")
                                        ]),
                                        _vm._l(_vm.days_number, function(d, i) {
                                          return _c(
                                            "td",
                                            {
                                              key: i,
                                              class: _vm.getClassCell(d, hour),
                                              attrs: { day: d, hour: hour.id },
                                              on: {
                                                dragover: function($event) {
                                                  $event.preventDefault()
                                                },
                                                drop: function($event) {
                                                  return _vm.dragFinish(
                                                    { day: d, hour: hour },
                                                    $event
                                                  )
                                                },
                                                click: function($event) {
                                                  return _vm.selectGridClick(
                                                    d,
                                                    hour
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.getGrids(d, hour),
                                              function(grid, i) {
                                                return _c("sys-schedule-card", {
                                                  key: i,
                                                  staticClass: "ma-1 pa-0",
                                                  class: _vm.getClassCard(
                                                    d,
                                                    hour
                                                  ),
                                                  style: _vm.getPosition(i),
                                                  attrs: {
                                                    grid: grid,
                                                    classe: _vm.getClasses(
                                                      grid,
                                                      { day: d, hour: hour }
                                                    ),
                                                    location: {
                                                      day: d,
                                                      hour: hour
                                                    },
                                                    showData:
                                                      !_vm.stackUp ||
                                                      i + 1 ==
                                                        _vm.getGrids(d, hour)
                                                          .length
                                                  },
                                                  on: {
                                                    onDelete: function($event) {
                                                      return _vm.deleteSchedule(
                                                        grid,
                                                        $event
                                                      )
                                                    },
                                                    onLock: function($event) {
                                                      return _vm.lock(
                                                        $event,
                                                        d,
                                                        hour
                                                      )
                                                    },
                                                    onDragstartCard: function(
                                                      $event
                                                    ) {
                                                      return _vm.dragStart(
                                                        { day: d, hour: hour },
                                                        $event
                                                      )
                                                    },
                                                    onDragendCard:
                                                      _vm.dragEndCard,
                                                    mouseover: function(
                                                      $event
                                                    ) {
                                                      return _vm.cartToFront(
                                                        $event,
                                                        true,
                                                        i
                                                      )
                                                    },
                                                    mouseleave: function(
                                                      $event
                                                    ) {
                                                      return _vm.cartToFront(
                                                        $event,
                                                        false
                                                      )
                                                    },
                                                    onClickCard: function(
                                                      $event
                                                    ) {
                                                      return _vm.selectCard(
                                                        $event,
                                                        d,
                                                        hour
                                                      )
                                                    }
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("span", [
                    _vm.group
                      ? _c("span", { staticClass: "blue--text" }, [
                          _vm._v(_vm._s(_vm.group.name))
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm.group
                            ? _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto elevation-0 ma-1",
                                  staticStyle: {
                                    "overflow-y": "scroll",
                                    "max-height": "400px"
                                  }
                                },
                                [
                                  _vm.renderComponent
                                    ? _c("SysScheduleList", {
                                        attrs: {
                                          model: _vm.selectedGrid,
                                          group: _vm.group
                                            ? _vm.group
                                            : { name: "" },
                                          items: _vm.group
                                            ? _vm.group.grids
                                            : [],
                                          title: _vm.group
                                            ? _vm.group.name
                                            : "",
                                          colors: _vm.colors,
                                          hours: _vm.hours
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.selectedGrid = $event
                                          },
                                          click: _vm.showGridInfo,
                                          mousedown: _vm.cancelSelection,
                                          onDrawer: function($event) {
                                            _vm.drawer = true
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("sys-schedule-joins", {
                            attrs: {
                              title:
                                "Junção [" +
                                (_vm.join ? _vm.join.join_id : "") +
                                "]",
                              items: _vm.groupJoins,
                              show: _vm.showJoins
                            },
                            on: {
                              "update:show": function($event) {
                                _vm.showJoins = $event
                              },
                              "on-select": function($event) {
                                return _vm.selectGroup($event, false)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.visible
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("sys-schedule-hours", {
                        attrs: {
                          item: _vm.teacherShow,
                          hours: _vm.hours,
                          group: _vm.group,
                          loading: _vm.loadingTeacher,
                          shifts: _vm.shifts,
                          showHours: _vm.showHours,
                          type: "teacher"
                        },
                        on: {
                          selectGroup: function($event) {
                            return _vm.selectGroup($event, false)
                          },
                          showJoins: function($event) {
                            return _vm.showGridInfo($event, false)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("sys-schedule-hours", {
                        attrs: {
                          item: _vm.ambienceShow,
                          hours: _vm.hours,
                          group: _vm.group,
                          loading: _vm.loadingAmbience,
                          shifts: _vm.shifts,
                          showHours: _vm.showHours,
                          type: "ambience"
                        },
                        on: {
                          selectGroup: function($event) {
                            return _vm.selectGroup($event, false)
                          },
                          showJoins: function($event) {
                            return _vm.showGridInfo($event, false)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("sys-schedule-items", {
                        attrs: {
                          title: "Professores",
                          items: _vm.historyTeachers
                        },
                        on: {
                          onClick: function($event) {
                            return _vm.loadTeachersHours($event)
                          },
                          "on-open": _vm.openDialogTeacher
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("sys-schedule-items", {
                        attrs: { title: "Turmas", items: _vm.historyGroups },
                        on: {
                          "on-select": function($event) {
                            return _vm.selectGroup($event, true)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isMaster
        ? _c("sys-mail-hours", {
            attrs: { show: _vm.showMailHourTeacher },
            on: {
              "update:show": function($event) {
                _vm.showMailHourTeacher = $event
              }
            }
          })
        : _vm._e(),
      _c("sys-register-time-table-date", {
        attrs: { show: _vm.showCalendar, model: _vm.modelClasse },
        on: {
          "update:show": function($event) {
            _vm.showCalendar = $event
          },
          "update:model": function($event) {
            _vm.modelClasse = $event
          },
          save: _vm.saveByDate
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          show: _vm.snackbar,
          icon: _vm.error.icon,
          title: _vm.error.title,
          color: "error",
          colorIcon: "white",
          classTitle: "white--text title-1",
          textCancel: "Fechar"
        },
        on: {
          "update:show": function($event) {
            _vm.snackbar = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c("div", { staticClass: "text-center" }, [
                  _c("span", { staticClass: "subtitle-2 text-center" }, [
                    _vm._v(_vm._s(_vm.error.message))
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.progress } }),
      _c("sys-schedule-drawer", {
        attrs: {
          model: _vm.drawer,
          items: _vm.groups,
          pendencies: _vm.pendencies
        },
        on: {
          "update:model": function($event) {
            _vm.drawer = $event
          },
          "on-select": function($event) {
            return _vm.selectGroup($event, true)
          },
          "on-dblclick": function($event) {
            return _vm.openDialogGroup($event)
          },
          "open-dialog-new": function($event) {
            return _vm.openDialogGroup(null)
          }
        }
      }),
      _c("sys-schedule-speed", {
        on: {
          onClickEye: function($event) {
            _vm.visible = $event
          },
          onShowHours: function($event) {
            _vm.showHours = $event
          },
          onMenu: function($event) {
            _vm.menu = !_vm.menu
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }